import Vue from 'vue'
import AMap from 'vue-amap'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/style/common.css' // global css
// import "font-awesome/css/font-awesome.css"
// import Cookies from 'js-cookie'
import BaiduMap from 'vue-baidu-map'
import { BmlMarkerClusterer } from 'vue-baidu-map'
import axios from 'axios'
//将axios挂载到原型上
Vue.prototype.$axios = axios

//配置全局的axios默认值（可选）

axios.defaults.baseURL = 'https://aip.baidubce.com'
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded'
Vue.component('blm-marker-cluster', BmlMarkerClusterer)
Vue.use(BaiduMap, {
  ak: 'Liz5cx8NFb67bi5f97L81wjBufDzl6ew',
})

import VueQuillEditor from 'vue-quill-editor'
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.use(VueQuillEditor, {
  placeholder: '请输入内容',
})
// vant 组件引入
import Vant from 'vant'
import 'vant/lib/index.css'
import VueCanvasPoster from 'vue-canvas-poster'
import './utils/wxShare'

import { dateFormat } from './utils/moment'
Vue.filter('dateFormat', dateFormat)
Vue.use(Vant)
Vue.use(AMap)
Vue.config.productionTip = false
Vue.use(VueCanvasPoster)
Vue.prototype.$host = '/baidu'
// 初始化vue-amap
AMap.initAMapApiLoader({
  // 高德key
  key: '8859b8e7a76bd03f160f3a208cfe9b1e',
  // 插件集合 （插件按需引入）
  plugin: ['AMap.Geolocation'],
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
