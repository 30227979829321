/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const router = {
  path: '/guanghe',
  component: Layout,
  children: [
    {
      path: '',
      name: 'guanghe',
      meta: {
        title: '晔光小站',
      },
      component: () => import('@/views/guanghe/index.vue'),
    },
    {
      path: 'detail/:Id',
      name: 'detail',
      meta: {
        title: '服务详情',
      },
      component: () => import('@/views/guanghe/detail.vue'),
    },
    // {
    //   path: '/guanghe/bianmin',
    //   name: 'bianmin',
    //   meta: {
    //     title: '便民事宜',
    //   },
    //   component: () => import('@/views/guanghe/bianmin.vue'),
    // },
    {
      path: '/guanghe/newAdd',
      name: 'newAdd',
      meta: {
        title: '房屋租赁',
      },
      component: () => import('@/views/guanghe/newAdd.vue'),
    },
    {
      path: '/huayang/ShowRoom',
      name: 'ShowRoom',
      meta: {
        title: '看房预约',
      },
      component: () => import('@/views/huayang/ShowRoom.vue'),
    },
    // {
    //     path: '/guanghe/brief',
    //     name: 'brief',
    //     meta: {
    //         title: "晔光小站简介"
    //     },
    //     component: () =>
    //         import ('@/views/guanghe/brief.vue')
    // },
    // {
    //   path: '/guanghe/evaluating',
    //   name: 'evaluating',
    //   meta: {
    //     title: '建言献策',
    //   },
    //   component: () => import('@/views/guanghe/evaluating.vue'),
    // },
    {
      path: '/guanghe/zhiyuan',
      name: 'zhiyuan',
      meta: {
        title: '志愿活动',
      },
      component: () => import('@/views/guanghe/zhiyuan.vue'),
    },
  ],
}

export default router
