/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

// 邻里中心
const router = {
  path: '/huayang',
  component: Layout,
  children: [
    {
      path: 'classroom/:id',
      meta: {
        title: '邻里中心详情',
      },
      component: () => import('@/views/huayang/classroom.vue'),
    },
    {
      path: 'company/:ShopId',
      meta: {
        title: '商铺详情',
      },
      component: () => import('@/views/huayang/company.vue'),
    },
    {
      path: '/shop/myShopOrder/index/PingJia/:orderId',
      name: 'PingJia',
      meta: {
        title: '订单评价',
      },
      component: () => import('@/views/huayang/firm.vue'),
    },
    {
      path: 'home/:RoomId',
      meta: {
        title: '食堂详情',
      },
      component: () => import('@/views/huayang/home.vue'),
    },
    {
      path: 'index/:Id',
      meta: {
        title: '美食详情',
      },
      component: () => import('@/views/huayang/index.vue'),
    },
    {
      path: '/huayang/interaction/particulars/:id',
      name: 'particulars',
      meta: {
        title: '美食列表',
      },
      component: () => import('@/views/huayang/particulars.vue'),
    },
    {
      path: '/huayang/interaction/particulars/resource:id/:roomId/:goodsId',
      name: 'resource',
      meta: {
        title: '美食信息',
      },
      component: () => import('@/views/huayang/resource.vue'),
    },
    {
      path: '/huayang/interaction/serve/:roomId',
      name: 'serve',
      meta: {
        title: '美食分类',
      },
      component: () => import('@/views/huayang/serve.vue'),
    },
    {
      path: '/huayang/interaction/serve/suggest/:id/:SortId/:roomId',
      name: 'suggest',
      meta: {
        title: '美食分类',
      },
      component: () => import('@/views/huayang/suggest.vue'),
    },
    {
      path: '/guanghe/bianmin/:roomId',
      name: 'bianmin',
      meta: {
        title: '食堂订单',
      },
      component: () => import('@/views/guanghe/bianmin.vue'),
    },
    {
      path: '/guanghe/canteen',
      name: 'canteen',
      meta: {
        title: '食堂订单',
      },
      component: () => import('@/views/guanghe/canteen.vue'),
    },
    {
      path: '/huayang/myPreRoom',
      name: 'myPreRoom',
      meta: {
        title: '服务预约',
      },
      component: () => import('@/views/huayang/myPreRoom.vue'),
    },
    {
      path: '/huayang/shopList',
      name: 'shopList',
      meta: {
        title: '我的订单',
      },
      component: () => import('@/views/huayang/shopList.vue'),
    },
    {
      path: '/huayang/FnRoomList/:roomId',
      name: 'FnRoomList',
      meta: {
        title: '预约列表',
      },
      component: () => import('@/views/huayang/FnRoomList.vue'),
    },
    {
      path: '/guanghe/brief/:orderId',
      name: 'brief',
      meta: {
        title: '订单评价',
      },
      component: () => import('@/views/guanghe/brief.vue'),
    },
    {
      path: '/guanghe/detail/:OId/:roomId',
      name: 'detail',
      meta: {
        title: '详情',
      },
      component: () => import('@/views/guanghe/detail.vue'),
    },
    {
      path: '/guanghe/index/:LeaseId',
      meta: {
        title: '房屋详情',
      },
      component: () => import('@/views/guanghe/index.vue'),
    },
    {
      path: '/huayang/Deal/:leaseId/:preId',
      name: 'Deal',
      meta: {
        title: '处理看房预约',
      },
      component: () => import('@/views/huayang/Deal.vue'),
    },
    {
      path: '/huayang/fuwu',
      name: 'Deal',
      meta: {
        title: '便民服务',
      },
      component: () => import('@/views/huayang/fuwu.vue'),
    },
    {
      path: '/huayang/xiangq',
      name: 'xiangq',
      meta: {
        title: '详情',
      },
      component: () => import('@/views/huayang/xiangq.vue'),
    },
    {
      path: '/neighbor/detail/:id',
      meta: {
        title: '详情',
      },
      component: () => import('@/views/neighbor/detail.vue'),
    },
  ],
}

export default router
